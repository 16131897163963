'use strict';
import * as _ from 'lodash';
import jsonLogic from 'json-logic-extension';
import "./formio.templates";
import {Formio} from 'formiojs';
import {jsonLogic as realJsonLogic} from 'formiojs/utils/utils';
import {backend, removeSaveBtnClasses} from "./backend.service";
import vNotify from "./notify.service";
import utils from './utils';
import jwtDecode from 'jwt-decode';

Object.keys(jsonLogic.customOperations).forEach(function (k) {
    realJsonLogic.add_operation(k, jsonLogic.customOperations[k]);
});


function getApplicationFileEventData(form_data) {
    return {
        detail: {
            file_id: options.file_id,
            file: form_data ? form_data : options.file_content,
            service: options.service,
            file_info: options.file_info
        }
    }
}

function isApplicantPaymentActive() {
    return options.active_task && options.active_task.assignee === 'applicantPayment';
}

function getIVeriCallbackRedirect(status) {
    let url = location.href;
    const param = "goToPage=parta-pay-link"
    if (!url.includes(param)) {
        if (url.includes("?")) {
            url += `&${param}`
        } else {
            url += `?${param}`
        }
    }

    return location.protocol + "//" + location.host + "/backend/iveri/" + status + "?redirect=" + encodeURIComponent(url);
}

/**
 * Switches the form to the proper page ... if the page is visible
 *
 * The pages names are:
 *   "parta-documents-link"
 *   "parta-guide-link"
 *   "parta-form-link"
 *   "parta-pay-link"
 *   "parta-send-link"
 *
 * @param paneName
 */
function gotoPage(form, page_name) {
    if (!form || !Array.isArray(form.pages)) return;
    form.pages.some((item, idx) => {
        if (item.component.title == page_name) {
            form.setPage(idx);
            return;
        }
    });
}

async function pingTransaction(transactionId) {
    let status = await backend.checkSingleTransaction(transactionId);

    if (!status) {
        console.log('Checking transaction status again in 5 seconds');
        await new Promise(resolve => setTimeout(resolve, 5000));
        return await pingTransaction(transactionId);
    } else {
        return status;
    }
}

function hideTransactionContainers() {
    const transactionContainerSuccess = document.getElementById("payment-transaction-success");
    const transactionContainerFail = document.getElementById("payment-transaction-fail");
    const transactionContainerPending = document.getElementById("payment-transaction-pending");

    const elements = [transactionContainerSuccess, transactionContainerFail, transactionContainerPending];

    elements.forEach(element => {
      if (!element.classList.contains('hidden')) {
        element.classList.add('hidden');
      }
    });
}

function onSubmitPayment(form, event) {
    console.log('onSubmitPayment');
    if (event && event.preventDefault) {
        event.preventDefault();
    }

    if (GLOBALS.LOCKS.paymentInProgress || GLOBALS.LOCKS.submissionInProgress) return;

    var hasData = () => {
    };
    var file_id = false;
    var file_data = false;

    addEventListener('applicationFileData', (ev) => {
        file_data = ev.detail;
        file_id = ev.detail.file_id;
        hasData(ev.detail.file);
    });

    dispatchEvent(new CustomEvent("requestFileData"));

    dispatchEvent(new CustomEvent("saveData"));

    GLOBALS.LOCKS.paymentInProgress = true;
    GLOBALS.LOCKS.submissionInProgress = true;

    // Make sure we have users data available, before we continue with the chain
    var update = new Promise(resolve => {
        if (!file_id) {
            // wait until event loader resolves it
            hasData = resolve;
        } else {
            resolve(file_data.file);
        }
    });


    update.then(function (file_data) {
        var data = {};
        console.log('dennn', file_data);

        // push it back
        file_data.isPayedCash = false;
        file_data.isPayedDigitally = false;

        if (file_data.process_id) {
            data['processId'] = file_data.process_id;
        }
        if (options.process_instance_id) {
            data['processId'] = options.process_instance_id;
        }
        if (file_data.file_id) {
            data['fileId'] = file_data.file_id;
        }
        if (options.file_id) {
            data['fileId'] = options.file_id;
        }
        if (!file_data.paymentProviderSelected) {
            // In case there's a number suffixed
            Object.keys(file_data).forEach(function (key) {
                if (key.startsWith("paymentProviderSelected")) {
                    file_data.paymentProviderSelected = file_data[key];
                }
            });
            if (!file_data.paymentProviderSelected) {
                file_data.paymentProviderSelected = "frontdesk";
            }
        }

        return backend.getTransactions(file_id).then((latestTransaction) => {
                if (latestTransaction && latestTransaction.status === GLOBALS.TRANSACTION_STATUS.OK) {
                    file_data.paymentProviderSelected = SERVICE_LATEST_TRANSACTION.transactionPaymentProvider;
                    file_data.isPayedDigitally = true;
                    file_data.payment_locked = true;
                    GLOBALS.LOCKS.submissionInProgress = false;
                    GLOBALS.LOCKS.paymentInProgress = false;
                    dispatchEvent(new CustomEvent('saveA5'));
                    return;
                }
                if (file_data.paymentProviderSelected === "frontdesk") {
                    file_data.isPayedCash = true;
                    GLOBALS.LOCKS.submissionInProgress = false;
                    hideTransactionContainers();
                    dispatchEvent(new CustomEvent('saveA5'));
                    return;
                }
                if (file_data.paymentProviderSelected !== "esadad") {
                    file_data.payment_locked = true;
                    dispatchEvent(new CustomEvent('saveData'));
                } else {
                    file_data.payment_locked = false;
                    dispatchEvent(new CustomEvent('saveData'));
                }
                form.triggerChange();
                if (file_data.paymentProviderSelected === "pagadito") {
                    if (latestTransaction && latestTransaction.status === GLOBALS.TRANSACTION_STATUS.PENDING) {
                        GLOBALS.LOCKS.paymentInProgress = false;
                        GLOBALS.LOCKS.submissionInProgress = false;
                        vNotify.error({text: GLOBALS.$translate('You cannot start a new transaction for this file while the previous transaction was not resolved. Try again later, please.')});
                        return;
                    }
                    file_data.isPayedCash = false;
                    GLOBALS.LOCKS.submissionInProgress = false;
                    var nit = null;

                    form.everyComponent((component) => {
                        if (component.component.tags && (component.component.tags.includes('nitnpe')
                            || component.component.tags.includes('nit')
                            || component.component.tags.includes('dui')) && file_data[component.key]) {
                            nit = file_data[component.key];
                            return;
                        }
                    });

                    backend.startPayment({
                        providerData: {nit: nit},
                        data: data,
                        providerCode: 'pagadito'
                    }).then(function (response) {
                        //redirect
                        location = response.transactionMetaData.pagoes_redirect;
                    }).catch(function (msg) {
                        console.log("----- submitpayment error -----", msg, "----- /submitpayment -----");
                        vNotify.error({
                            text: GLOBALS.$translate('We could not process your transaction. Try again later, please.'),
                            replaceMessage: true
                        });
                        GLOBALS.LOCKS.paymentInProgress = false;
                        GLOBALS.LOCKS.submissionInProgress = false;
                    });
                    return;
                }
                if (["npe", "cnr", "g2c", "leapa", "atd-cyber-source", "dohone", "fedapay", "tresorpay", "birms", "pesaflow", "esadad", "ngsystem"].includes(file_data.paymentProviderSelected)) {
                    if (latestTransaction && latestTransaction.status === GLOBALS.TRANSACTION_STATUS.PENDING) {
                        GLOBALS.LOCKS.paymentInProgress = false;
                        GLOBALS.LOCKS.submissionInProgress = false;
                        vNotify.error({text: GLOBALS.$translate('You cannot start a new transaction for this file while the previous transaction was not resolved. Try again later, please.')});
                        return;
                    }
                    file_data.isPayedCash = false;
                    GLOBALS.LOCKS.submissionInProgress = false;
                    var providerData = {
                        period: new Date().toISOString().slice(0, 10).replaceAll("-", "")
                    }
                    form.everyComponent((component) => {
                        if (["npe", "cnr"].includes(file_data.paymentProviderSelected)) {
                            if (component.component.tags && component.component.tags.includes('nit')) {
                                providerData.nit = file_data[component.key];
                                return;
                            }
                        }
                        if (file_data.paymentProviderSelected === 'g2c') {
                            if (!component.component.tags) {
                                return;
                            }
                            component.component.tags.some((tag) => {
                                if (['agencyCode', 'accountHeadId'].includes(tag)) {
                                    providerData[tag] = file_data[component.key]
                                    return true;
                                }
                            });
                        }
                        if (file_data.paymentProviderSelected === 'fedapay') {
                            if (!component.component.tags) {
                                return;
                            }
                            component.component.tags.some((tag) => {
                                if (['mobile-payment-phoneNo'].includes(tag)) {
                                    providerData[tag] = file_data[component.key]
                                    return true;
                                }
                            });
                        }
                        if (file_data.paymentProviderSelected === "ngsystem") {
                             if (!component.component.tags) {
                                return;
                            }

                            component.component.tags.some((tag) => {
                                if (['ngsystem-payment-method'].includes(tag)) {
                                    providerData[tag] = file_data[component.key]
                                    return true;
                                }
                            });

                            component.component.tags.some((tag) => {
                                if (['mobile-payment-phoneNo'].includes(tag)) {
                                    providerData[tag] = file_data[component.key]
                                    return true;
                                }
                            });
                        }
                        if (file_data.paymentProviderSelected === 'tresorpay') {
                            if (!component.component.tags) {
                                return;
                            }
                            component.component.tags.some((tag) => {
                                if (['payment-enterprise', 'payment-name', 'payment-surname', 'payment-region',
                                    'payment-department', 'payment-district', 'payment-town', 'payment-area',
                                    'payment-postalCode', 'payment-localisation', 'payment-phone'].includes(tag)) {
                                    providerData[tag] = file_data[component.key]
                                    return true;
                                }
                            });
                        }
                        if (file_data.paymentProviderSelected === 'birms') {
                            if (!component.component.tags) {
                                return;
                            }
                            component.component.tags.some((tag) => {
                                if (['birms-tax-payer-number', 'birms-tax-payer-document-number', 'birms-agency-code',
                                    'birms-payer-mobile', 'birms-tax-payer-name'].includes(tag)) {
                                    providerData[tag] = file_data[component.key]
                                    return true;
                                }
                            });
                        }
                    });
                    backend.startPayment({
                        providerData: providerData,
                        data: data,
                        providerCode: file_data.paymentProviderSelected
                    }).then(function (response) {
                        backend.getTransactions(data['fileId']).then((latestTransaction) => {
                            // G2C fix TOBE-12040
                            window.SERVICE_LATEST_TRANSACTION = latestTransaction;
                            form.triggerChange();
                            form.emit('paymentPageValidateTheForm_DISABLED');
                        });
                        if (["cnr", "g2c", "atd-cyber-source", "dohone", "leapa", "fedapay", "tresorpay", "birms", "pesaflow", "esadad", "ngsystem"].includes(file_data.paymentProviderSelected)) {
                            if (file_data.paymentProviderSelected === "g2c") {
                                isG2cJustSelected = true;
                                let element = document.getElementById("g2c-payment-iframe");
                                element.setAttribute("src", response.transactionMetaData.redirect);
                                element.removeAttribute("hidden");
                                window.scrollTo(0, 0);
                                pingTransaction(response.transactionId).then((status) => {
                                    element.setAttribute("hidden", true);
                                    let url = window.location.href;
                                    const param = "goToPage=parta-pay-link"
                                    if (!url.includes(param)) {
                                        if (url.includes("?")) {
                                            url += `&${param}`
                                        } else {
                                            url += `?${param}`
                                        }
                                    }
                                    window.location.href = url;
                                });
                            } else if (file_data.paymentProviderSelected === "birms") {
                                window.open(response.transactionMetaData.content.redirectUrl, '_blank');
                            } else if (file_data.paymentProviderSelected === "leapa") {
                                setUpLeapaContainer(file_data.file_id || options.file_id, response.transactionMetaData);
                            } else if (file_data.paymentProviderSelected === "ngsystem") {
                                if (providerData['ngsystem-payment-method'] === "orange" && response.transactionMetaData && response.transactionMetaData.redirect) {
                                    window.location = response.transactionMetaData.redirect;
                                    return;
                                }

                                // For mobile money methods, show appropriate message
                                let message = GLOBALS.$translate('Payment request sent. Please check your phone to confirm the payment.');
                                vNotify.success({text: message});
                            } else if (["atd-cyber-source", "dohone"].includes(file_data.paymentProviderSelected)) {
                                handleAutoSubmittingPaymentForm(file_data.paymentProviderSelected,
                                    response.transactionData, response.transactionMetaData);
                            } else if (file_data.paymentProviderSelected === "esadad") {
                                let affected = false;
                                utils.getTaggedFormioComponents(form, 'payment-transaction-id').forEach(
                                    function (component) {
                                        component.tags.forEach(function (tag) {
                                        if (!tag.startsWith('payment-transaction-id')) {
                                            return;
                                        }

                                        const voucherComponent = form.getComponent(component.key);
                                        voucherComponent.setValue(response.transactionId);
                                        affected = true;
                                        form.triggerChange();
                                    }
                                    )
                                });
                                if (response.transactionMetaData.bill_no) {
                                    utils.getTaggedFormioComponents(form, 'payment-bill-no').forEach(
                                        function (component) {
                                            component.tags.forEach(function (tag) {
                                            if (!tag.startsWith('payment-bill-no')) {
                                                return;
                                            }

                                            const comp = form.getComponent(component.key);
                                            comp.setValue(response.transactionMetaData.bill_no);
                                            affected = true;
                                            form.triggerChange();
                                        }
                                        )
                                    });
                                }
                                if (affected) {
                                    dispatchEvent(new CustomEvent('saveDraft'));
                                }
                                window.scrollTo(0, 0);
                                pingTransaction(response.transactionId).then((status) => {
                                    window.location.search += '&goToPage=parta-pay-link'
                                });
                            } else {
                                window.location = response.transactionMetaData.redirect;
                            }
                            return;
                        }
                        window.location.reload()
                    }).catch(function (msg) {
                        console.log("----- submitpayment error -----", msg, "----- /submitpayment -----");
                        let errorText = GLOBALS.$translate('We could not process your transaction. Try again later, please.');
                        if (msg.error) {
                            errorText += `\n\nError: ${msg.error}`;
                        }
                        if (msg.errors) {
                            errorText += `\n\nErrors: ${JSON.stringify(msg.errors)}`;
                        }
                        vNotify.error({
                            text: errorText,
                            replaceMessage: true
                        });
                        GLOBALS.LOCKS.paymentInProgress = false;
                        GLOBALS.LOCKS.submissionInProgress = false;
                        if (event && event.detail && event.detail.id) {
                            dispatchEvent(new CustomEvent("statusFeedback", {
                                detail: {
                                    id: event.detail.id,
                                    status: "ERROR"
                                }
                            }));
                        }
                    });
                    return;
                }
                if (file_data.paymentProviderSelected === "iveri") {
                    var transactionData = {};
                    // FIXME: Why do we need this client-side?
                    transactionData['Lite_Currency_AlphaCode'] = "ZAR";
                    transactionData['Lite_Merchant_ApplicationId'] = GLOBALS.IVERI_MERCHANT_APPLICATION_ID;
                    if (isApplicantPaymentActive()) {
                        gotoPage(form, 'parta-pay-link');
                        transactionData['Lite_Website_Successful_Url'] = location.protocol + "//" + location.host + "/backend/iveri/1?redirect=" + encodeURIComponent(location.href);
                    } else {
                        transactionData['Lite_Website_Successful_Url'] = getIVeriCallbackRedirect(1)
                    }
                    transactionData['Lite_Website_Fail_Url'] = getIVeriCallbackRedirect(2);
                    transactionData['Lite_Website_TryLater_Url'] = getIVeriCallbackRedirect(4);
                    transactionData['Lite_Website_Error_Url'] = getIVeriCallbackRedirect(3);
                    transactionData['Lite_ConsumerOrderID_PreFix'] = "LITE";
                    transactionData['Ecom_BillTo_Online_Email'] = "some@email.com";
                    transactionData['Ecom_Payment_Card_Protocols'] = "IVERI";
                    transactionData['Ecom_ConsumerOrderId'] = null;
                    transactionData['Ecom_TransactionComplete'] = false;


                    backend.startPayment({
                        providerData: transactionData,
                        data: data
                    }).then(function (response) {
                        // post the form
                        var payForm = document.createElement("form");
                        payForm.setAttribute("method", 'post');
                        payForm.setAttribute("action", 'https://portal.nedsecure.co.za/Lite/Authorise.aspx');
                        transactionData = response.transactionMetaData.formData;
                        Object.keys(transactionData).forEach(function (key) {
                            var hiddenField = document.createElement("input");
                            hiddenField.setAttribute("type", "hidden");
                            hiddenField.setAttribute("name", key);
                            hiddenField.setAttribute("value", transactionData[key]);
                            payForm.appendChild(hiddenField);
                        })
                        document.body.appendChild(payForm);
                        GLOBALS.LOCKS.paymentInProgress = false;
                        payForm.submit();
                    }).catch(function (msg) {
                        console.log("----- submitpayment error -----", msg, "----- /submitpayment -----")
                        vNotify.error({text: GLOBALS.$translate('We could not process your transaction. Try again later, please.')});
                    });
                }
            });
    }).finally(() => {
        GLOBALS.LOCKS.paymentInProgress = false;
        GLOBALS.LOCKS.submissionInProgress = false;
    });

}

var allowedAttrs = [
    'ref',
    'src',
    'url',
    'data-oembed-url',
    'onclick',
    'onchange',
    'style',
    'id',
    'checked',
    'width',
    'height',
    'hidden',
    'stroke-width',
    'fill',
    'r',
    'cx',
    'cy',
    'stroke-linecap',
    'data'
];

var allowedTags = [
    "object",
    "iframe"
];

var formOpts = {
    sanitizeConfig: {
        allowedAttrs: allowedAttrs,
        addAttr: allowedAttrs,
        allowedTags: allowedTags,
        addTags: allowedTags
    },
    buttonSettings: {
        showCancel: false,
        showPrevious: false,
        showNext: false,
        showSubmit: false
    },
    i18n: {},
};

let isG2cSuccess = false;
let isG2cJustSelected = false;
let g2cIframe = null;
let leapaContainer = null;
let autoSubmittingPaymentForm = null;

function onPrintAnyForm(form, event) {
    //Helpers.printAnyForm({ data: self.form.data, formId: ev.detail, service_id: self.service_id });
    backend.postFormOverviewData({
        data: form.submission.data,
        formId: event.detail,
        serviceId: options.service.service_id,
    }).then(function (res) {
        if (res && res.code) {
            var url = location.origin + '/form-preview-pdf/' + res.code;
            var win = window.open(url, '_blank');
            win.focus();
        }
    });
}

function setAutoSubmittingPaymentFormFields(paymentFormDomElement, formData, formAction) {
    if (formAction) {
        autoSubmittingPaymentForm.action = formAction;
    }
    Object.keys(formData).forEach(function (key) {
        const input = document.createElement('input');
        input.type = 'text';
        input.name = key;
        input.setAttribute('value', formData[key]);
        autoSubmittingPaymentForm.appendChild(input);
    });
}

function handleAutoSubmittingPaymentForm(provider, transactionDataSent, transactionMetadata) {
    let formAction;
    const formData = transactionMetadata.formData;
    if (provider === 'dohone') {
        autoSubmittingPaymentForm = document.getElementById('dohone-redirect-form');
    } else if (provider === 'atd-cyber-source') {
        autoSubmittingPaymentForm = document.getElementById('atd-redirect-form');
        formAction = transactionMetadata.hostUrl;
    }
    setAutoSubmittingPaymentFormFields(autoSubmittingPaymentForm, formData, formAction);

    autoSubmittingPaymentForm.submit();
}

function setUpLeapaContainer(fileId, transactionMetadata) {
    if (!leapaContainer) {
        leapaContainer = document.getElementById('leapa-customer');
        leapaContainer.addEventListener("onSuccess", (e) => {
            console.log(e);
            let chargeId = e.detail?.id;
            if (!chargeId) {
                chargeId = e.detail?.charge?.id;
            }
            fetch(`/leapa-payment-status/${chargeId}`, {
                method: 'POST', headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }, body: JSON.stringify(e.detail)
            }).then((response) => {
                return new Promise((resolve) => response.json()
                    .then((json) => resolve({
                        status: response.status,
                        ok: response.ok,
                        json,
                    })));
            }).then(({status, json, ok}) => {
                const message = json.message;
                switch (status) {
                    case 400:
                    case 402:
                    case 500:
                        dispatchEvent(new CustomEvent("notifyError", {
                            "detail":
                                `${GLOBALS.$translate("Leapa payment verification failed")}: ${message}`
                        }))
                        break;
                    case 201:
                    case 200:
                        dispatchEvent(new CustomEvent("notifySuccess", {
                            "detail":
                                GLOBALS.$translate("Leapa payment successful")
                        }))
                        setTimeout(() => {
                            // This will also redirect
                            window.location.search += '&goToPage=parta-send-link'
                        }, 1000);
                        break;
                    default:
                        console.log({status, json, ok});
                        break;
                }
            })
        });
        leapaContainer.addEventListener("onFailure", (e) => {
            console.log(e);
            dispatchEvent(new CustomEvent("notifyError", {
                "detail":
                    `${GLOBALS.$translate("Leapa payment failed")}: ${e.detail.message}`
            }))
            $('#formio-form-wizard').show();
        });
    }
    leapaContainer.setAttribute('description', transactionMetadata.description);
    leapaContainer.setAttribute('amount', transactionMetadata.amount);
    leapaContainer.setAttribute('currency', transactionMetadata.currency);
    // leapaContainer.setAttribute('invoice-id', transactionMetadata.invoiceId);
    // leapaContainer.setAttribute('customer-id', transactionMetadata.customerId);
    leapaContainer.setAttribute('first-name', transactionMetadata.firstName);
    leapaContainer.setAttribute('last-name', transactionMetadata.lastName);
    leapaContainer.setAttribute('email', transactionMetadata.email);
    // leapaContainer.setAttribute('button-text', GLOBALS.$translate('Pay with Leapa'));
    leapaContainer.removeAttribute('hidden');
    $('#formio-form-wizard').hide();
}

export function createForm(elementId, schema) {
    let form = null;

    formOpts['language'] = CURRENT_LANGUAGE;
    formOpts['i18n'][CURRENT_LANGUAGE] = GLOBALS.TRANSLATIONS;
    if (GLOBALS.DECIMAL_SEPARATOR) formOpts['decimalSeparator'] = GLOBALS.DECIMAL_SEPARATOR;
    if (GLOBALS.DELIMITER) formOpts['thousandsSeparator'] = GLOBALS.DELIMITER;
    formOpts['noDefaults'] = true;
    var body = document.querySelector('body');
    if (!body.classList.contains('formio-new')) {
        body.classList.add('formio-new')
    }

    return backend.getTransactions(
        options.file_id,
        null,
        options.file_content.paymentProviderSelected
    ).then((latestTransaction) => {
        window.SERVICE_LATEST_TRANSACTION = latestTransaction;
        var transactionContainer = null;
        var transactionContainerCloseButton = null;
        if (!latestTransaction || latestTransaction.status == null) return;
        switch (latestTransaction.status) {
            case GLOBALS.TRANSACTION_STATUS.OK:
                transactionContainer = document.getElementById("payment-transaction-success");
                if (latestTransaction.payment_provider__code === "g2c") {
                    isG2cSuccess = true;
                    if (!g2cIframe) {
                        g2cIframe = document.getElementById('g2c-payment-iframe');
                    }
                    g2cIframe.setAttribute('src', latestTransaction.meta_data.redirect);
                    if (isApplicantPaymentActive()) {
                        g2cIframe.removeAttribute('hidden');
                    }
                }
                break;
            case GLOBALS.TRANSACTION_STATUS.FAIL:
            case GLOBALS.TRANSACTION_STATUS.ERROR:
                transactionContainer = document.getElementById("payment-transaction-fail");
                transactionContainerCloseButton = document.getElementById("payment-transaction-fail-closer");
                let messageContainer = document.getElementById("payment-transaction-message");
                if (messageContainer) {
                    messageContainer.innerHTML = latestTransaction.message;
                    transactionContainerCloseButton.addEventListener("click", (e) => {
                        console.log("--- close payment alert", e);
                        transactionContainer.classList.add("hidden");
                    });
                }
                break;
            case GLOBALS.TRANSACTION_STATUS.PENDING:
                transactionContainer = document.getElementById("payment-transaction-pending");
                if (latestTransaction.payment_provider__code === "leapa") {
                    setUpLeapaContainer(latestTransaction.file__file_id, latestTransaction.meta_data);
                } else if (latestTransaction.payment_provider__code === "atd-cyber-source") {
                    autoSubmittingPaymentForm = document.getElementById('atd-redirect-form');
                    setAutoSubmittingPaymentFormFields(autoSubmittingPaymentForm, latestTransaction.meta_data,
                        latestTransaction.meta_data.hostUrl);
                    // $('#payment-transaction-pending-continue-button').removeClass('hidden').click(() => {
                    //     autoSubmittingPaymentForm.submit();
                    // });
                }
                break;
            default:
                return;
        }
        if (transactionContainer) {
            transactionContainer.classList.remove("hidden");
        }
    }).catch(function (reason) {
        console.log("Could not fetch payments");
        console.log(reason);
    }).then(() => {
        formOpts = {...formOpts, submission: {data: options.file_content}};
        formOpts.submission.data.is_submit_allowed = Boolean(options.submit_allowed);
        return Formio.createForm(document.getElementById(elementId), schema, formOpts)
            .then(function (f) {
                form = f;
                // window.form = f;
                form.language = CURRENT_LANGUAGE;

                const tryToSelectCorrectPaymentMethod = () => {
                    if (window.SERVICE_LATEST_TRANSACTION) {
                        Array.prototype.forEach.call(document.getElementsByClassName('form-check-input'), function (each) {
                            if (each.name.includes('paymentProviderSelected')) {
                                if (each.value === window.SERVICE_LATEST_TRANSACTION.payment_provider__code) {
                                    each.checked = true;
                                    form.data['paymentProviderSelected'] = each.value;
                                } else {
                                    each.removeAttribute('checked');
                                }
                            }
                        });
                        switch (window.SERVICE_LATEST_TRANSACTION.status) {
                            case GLOBALS.TRANSACTION_STATUS.FAIL:
                            case GLOBALS.TRANSACTION_STATUS.ERROR:
                                form.data.payment_locked = false;
                                dispatchEvent(new CustomEvent('saveData'));
                        }
                    } else {
                        form.data.payment_locked = false;
                        dispatchEvent(new CustomEvent('saveData'));
                    }
                }

                tryToSelectCorrectPaymentMethod();

                form.on('change', (event) => {
                    if (!event.changed) {
                        return;
                    }
                    let changed = event.changed;
                    if (!form.changedFields) {
                        form.changedFields = {};
                    }
                    if (changed.component && changed.component.key) {
                        form.changedFields[changed.component.key] = form.submission.data[changed.component.key];
                    }
                    if (GLOBALS.LOCKS.DEBUG_FIELD_CHANGES) {
                        console.log('change');
                        if (changed && changed.component) {
                            console.log("Field '" + changed.component.key + "' has new value '" + changed.value + "'");
                        }
                    }
                    _.debounce(removeSaveBtnClasses, 500);
                });

                dispatchEvent(new CustomEvent("applicationFileLoaded", getApplicationFileEventData()));

                // Just a compatibility fix
                addEventListener("RELOAD_FORM", function (ev) {
                    dispatchEvent(new CustomEvent("changeFormHandler", {"detail": form}));
                });

                addEventListener("PAYMENT", onSubmitPayment.bind(undefined, f));
                addEventListener("PRINTOUT", onPrintAnyForm.bind(undefined, f));
                addEventListener("REGISTER_USER", (ev) => {
                    onRegistrationRedirect(ev, form);
                });

                form.on('submitError', (ev) => {
                    console.log('submitError', ev);
                });
                form.on('error', (ev) => {
                    console.log('error', ev);
                });
                form.on('confirm', (ev) => {
                    form.nextPage().then(() => {
                        return backend.saveFile(options.file_id, form.submission.data, false)
                    }).catch((ev) => {
                        console.log("Form does not validate", ev);
                    });
                });

                addEventListener('saveDraft', (ev) => {
                    console.log('saveDraft', ev.detail);
                    let partialSave = false;
                    let dataToSave = form.submission.data;
                    if (ev.detail && ev.detail.partialSave) {
                        partialSave = true;
                        dataToSave = form.changedFields;
                    }
                    backend.saveFile(options.file_id, dataToSave, false, partialSave).then(function () {
                        if (!ev || !ev.detail) {
                            return;
                        }
                        if (partialSave) {
                            form.changedFields = {};
                        }
                        if (ev.detail.id) {
                            dispatchEvent(new CustomEvent("statusFeedback", {
                                detail: {
                                    id: ev.detail.id,
                                    status: "OK"
                                }
                            }));
                        }
                        if (ev.detail.redirectUrl) {
                            window.location.href = ev.detail.redirectUrl;
                        }
                    }).catch((ev) => {
                        if (ev && ev.error) {
                            vNotify.error({text: GLOBALS.$translate(ev.error)});
                        }
                        if (!ev || !ev.detail || !ev.detail.id) return;
                        dispatchEvent(new CustomEvent("statusFeedback", {detail: {id: ev.detail.id, status: "ERROR"}}));
                    });
                });
                GLOBALS.saveDraftEventListenerDeclared = true;

                function saveAndGotoNextPage(ev) {
                    console.log('Event: ' + ev.type);
                    // there are no pages
                    if (!form.nextPage || form.isLastPage()) {
                        return submitData(ev);
                    }

                    // continue with pages
                    saveData(ev).then(() => {
                        return form.nextPage();
                    }).then(() => {
                        window.scrollTo(0, 0);
                    }).catch((err) => {
                        console.log("Form does not validate", err);
                    })
                }

                function onCancelFile(event) {
                    console.log('Event:', event.type);

                    backend.cancelFile(options.file_id).then(function () {
                            vNotify.success({text: GLOBALS.$translate("File cancelled!")});

                            window.location.href = "/";
                        }).catch(function (err) {
                            console.log('Cancelling file failed:', err)

                            vNotify.error({text: GLOBALS.$translate("Unable to cancel file!")});
                        });
                }

                function saveData(ev) {
                    return new Promise((resolve, reject) => {
                        if (ev && ev.detail && ev.detail.validate) {
                            if (!form.checkValidity(form.localData, true, form.localData, true)) {
                                form.showErrors([], true);
                                const ret = {detail: {id: ev.detail.id, status: "validation error"}};
                                if (ev.detail.callback) {
                                    ev.detail.callback(ret)
                                } else if (ev.detail.id) {
                                    dispatchEvent(new CustomEvent("statusFeedback", ret));
                                }
                                reject();
                            }
                        }
                        backend.saveFile(options.file_id, form.submission.data, false).then(function () {
                            if (ev && ev.detail) {
                                const ret = {detail: {id: ev.detail.id, status: "OK", savedFileSuccessfully: true}};
                                if (ev.detail.callback) {
                                    ev.detail.callback(ret);
                                } else if (ev.detail.id) {
                                    dispatchEvent(new CustomEvent("statusFeedback", ret));
                                }
                            }
                            resolve();
                        }).catch((ev) => {
                            if (ev) {
                                if (ev.detail) {
                                    const ret = {detail: {id: ev.detail.id, status: "ERROR"}};
                                    if (ev.detail.callback) {
                                        ev.detail.callback(ret);
                                    } else if (ev.detail.id) {
                                        dispatchEvent(new CustomEvent("statusFeedback", ret));
                                    }
                                }
                                if (ev.error) {
                                    vNotify.error({text: GLOBALS.$translate(ev.error)});
                                }
                            }
                            reject();
                        });
                    });
                }

                function goToNextPage(ev) {
                    form.nextPage().then(() => {
                        window.scrollTo(0, 0);
                        if (ev && ev && ev.detail && ev.detail.callback) {
                            ev.detail.callback({detail: {status: "OK"}});
                        }
                    }).catch((err) => {
                        console.log("Form does not validate", err);
                        if (ev && ev && ev.detail && ev.detail.callback) {
                            ev.detail.callback({detail: {status: "error", error: err}});
                        }
                    })
                }

                addEventListener('goToNextPage', goToNextPage);
                addEventListener('saveData', saveData);
                addEventListener('saveGUIDE', saveAndGotoNextPage);
                addEventListener('saveA5', saveAndGotoNextPage);
                addEventListener('A6', saveAndGotoNextPage);
                addEventListener('saveDOCUMENT', saveAndGotoNextPage);
                addEventListener('savePAYMENTPAGE', onSubmitPayment.bind(undefined, f));
                addEventListener('cancelFile', onCancelFile);
                // validate is needed when in applicant role for example
                addEventListener('validate', submitData);
                const onPaymentPagePossiblySelected = () => {
                    if (!g2cIframe) {
                        g2cIframe = document.getElementById('g2c-payment-iframe');
                    }
                    if ((isG2cSuccess || isG2cJustSelected) && f.pages) {
                        let paymentTabIsSelectedTab = f.pages.some((each, i) => {
                            if (i === f.page && each.component && each.component.label
                                && each.component.label.includes('pay-link')) {
                                return true;
                            }
                        })
                        if (paymentTabIsSelectedTab) {
                            g2cIframe.removeAttribute('hidden');
                        } else {
                            g2cIframe.setAttribute('hidden', true);
                        }
                    }
                    parseAllHtmlWithTranslateAttribute();
                    tryToSelectCorrectPaymentMethod();
                }

                form.on('wizardPageSelected', onPaymentPagePossiblySelected);
                // This fires too often and makes it impossible to change payment methods
                //form.on('pagesChanged', onPaymentPagePossiblySelected);
                form.on('nextPage', onPaymentPagePossiblySelected);
                form.on('prevPage', onPaymentPagePossiblySelected);

                form.on('saveDOCUMENT', saveAndGotoNextPage);
                form.on('savePAYMENTPAGE', onSubmitPayment.bind(undefined, f));
                form.on('saveA5', saveAndGotoNextPage);
                form.on('fileUploadingEnd', (fileUploadPromise) => {
                    fileUploadPromise.then(() => {
                        dispatchEvent(new CustomEvent('saveDraft'));
                    });
                });
                // form.on('fileDeletingEnd', (fileUploadPromise) => {
                //     fileUploadPromise.then(() => {
                //         dispatchEvent(new CustomEvent('saveDraft'));
                //     });
                // });

                function submitData(ev) {
                    console.log('Event for submit: ' + ev.type);
                    const outerEv = ev;
                    let fetchPromise = fetch('/site-auth-check?service_id=' + options.service.service_id +
                        '&lang=' + CURRENT_LANGUAGE);
                    fetchPromise.then((response) => response.json()).then((parsedResponse) => {
                        if (parsedResponse.status) {
                            if (!options.allow_anonymous && !options.user_email_verified
                                && !options.unverified_email_allowed) {
                                $('#verify-email-to-send').modal('show');
                                return;
                            }
                        } else {
                            $('#authenticate-to-send').modal('show');
                            return;
                        }

                        form.submit(null, {skipAlert: true}).then((submission) => {
                            if (options.process_instance_id) {
                                submission.data[options.active_task.camundaName + "selection"] = "filevalidated";
                                return backend.updateProcess(options.process_instance_id,
                                    options.active_task.camundaName, "WIZARD", submission.data)
                            } else {
                                return backend.startProcess(options.file_id, submission.data)
                            }
                        }).then(() => {
                            vNotify.success({text: GLOBALS.$translate("File submitted successfully!")});
                            setTimeout(() => {
                                window.location.href = window.location.origin;
                            }, 500);
                        }).catch((ev) => {
                            console.log('Error submitting', ev);
                            if (ev && ev.error) {
                                vNotify.error({text: GLOBALS.$translate(ev.error)});
                            }
                            if (outerEv && outerEv.detail && outerEv.detail.callback) {
                                outerEv.detail.callback({
                                    detail: {
                                        id: outerEv.detail.id,
                                        status: "Form submission failed"
                                    }
                                });
                            }
                        })
                    });
                }

                addEventListener("saveSENDPAGE", submitData);

                addEventListener('requestFileData', (ev) => {
                    dispatchEvent(new CustomEvent("applicationFileData", getApplicationFileEventData(form.submission.data)));
                });

                setTimeout(function () {
                    utils.dispatchPageFromHash(form)
                }, 300);

                const urlParams = new URLSearchParams(window.location.search)
                if (urlParams.get('goToPage') && form.pages) {
                    // FIXME: difference between this, dispatchPageFromHash, and gotoPage()?
                    form.pages.some((item, idx) => {
                        if (item.component.title === urlParams.get('goToPage')) {
                            form.setPage(idx);
                        }
                    });
                }

                if (
                    SERVICE_LATEST_TRANSACTION &&
                    SERVICE_LATEST_TRANSACTION.payment_provider__code === 'esadad'
                ) {
                    let affected = false;
                    if (SERVICE_LATEST_TRANSACTION.status === GLOBALS.TRANSACTION_STATUS.OK) {
                        if (SERVICE_LATEST_TRANSACTION.transactionMetaData.process_date) {
                            utils.getTaggedFormioComponents(form, 'esadad-payment-date').forEach(
                                function (component) {
                                    component.tags.forEach(function (tag) {
                                        if (!tag.startsWith('esadad-payment-date')) {
                                            return;
                                        }

                                        const comp = form.getComponent(component.key);
                                        const processDate = SERVICE_LATEST_TRANSACTION.transactionMetaData.process_date
                                        if (comp.getValue() !== processDate) {
                                            comp.setValue(processDate);
                                            affected = true;
                                            form.triggerChange();
                                        }
                                    })
                            });
                        }
                        if (SERVICE_LATEST_TRANSACTION.transactionMetaData.payer_name) {
                            utils.getTaggedFormioComponents(form, 'esadad-payer-name').forEach(
                                function (component) {
                                    component.tags.forEach(function (tag) {
                                        if (!tag.startsWith('esadad-payer-name')) {
                                            return;
                                        }

                                        const comp = form.getComponent(component.key);
                                        const payerName = SERVICE_LATEST_TRANSACTION.transactionMetaData.payer_name;
                                        if (comp.getValue() !== payerName) {
                                            comp.setValue(payerName);
                                            affected = true;
                                            form.triggerChange();
                                        }
                                    })
                            });
                        }
                    } else if (SERVICE_LATEST_TRANSACTION.status === GLOBALS.TRANSACTION_STATUS.FAIL) { // TODO: Check if necessary with updated logic?
                        // utils.getTaggedFormioComponents(form, 'payment-transaction-id').forEach(
                        //     function (component) {
                        //         component.tags.forEach(function (tag) {
                        //             if (!tag.startsWith('payment-transaction-id')) {
                        //                 return;
                        //             }
                        //             const comp = form.getComponent(component.key);
                        //             if (comp.getValue() !== '') {
                        //                 comp.setValue('');
                        //                 affected = true;
                        //                 form.triggerChange();
                        //             }
                        //         }
                        //         )
                        //     });
                        //
                        // utils.getTaggedFormioComponents(form, 'payment-bill-no').forEach(
                        //     function (component) {
                        //         component.tags.forEach(function (tag) {
                        //             if (!tag.startsWith('payment-bill-no')) {
                        //                 return;
                        //             }
                        //             const comp = form.getComponent(component.key);
                        //             if (comp.getValue() !== '') {
                        //                 comp.setValue('');
                        //                 affected = true;
                        //                 form.triggerChange();
                        //             }
                        //         }
                        //         )
                        //     });
                    }

                    if (affected) {
                        dispatchEvent(new CustomEvent('saveDraft'));
                    }
                }

                return form;
            });
    });
}


export function createPreviewForm(elementId, schema) {
    formOpts['i18n'][CURRENT_LANGUAGE] = GLOBALS.TRANSLATIONS;
    if (GLOBALS.DECIMAL_SEPARATOR) formOpts['decimalSeparator'] = GLOBALS.DECIMAL_SEPARATOR;
    if (GLOBALS.DELIMITER) formOpts['thousandsSeparator'] = GLOBALS.DELIMITER;
    formOpts['noDefaults'] = true;

    var body = document.querySelector('body');
    if (!body.classList.contains('formio-new')) {
        body.classList.add('formio-new');
    }

    return Formio.createForm(document.getElementById(elementId), schema, formOpts)
        .then(function (f) {
            const form = f;
            form.language = CURRENT_LANGUAGE;

            // We search for 'page' in hash and use the numeric value to switch to that page
            let hash = window.location.hash;
            if (hash && hash.indexOf("page") >= 0) {
                let pageNum = hash.substring(hash.indexOf("page") + 4);
                if (form.setPage) {
                    form.setPage(parseInt(pageNum, 10) - 1);
                }
            }

            form.on('loadData', function (event) {
                console.log('Event loadData', event);
                if (event.data) {
                    form.submission = {data: Object.assign(form.submission.data, event.data)};
                }
            });

            setTimeout(function () {
                utils.dispatchPageFromHash(form)
            }, 300);

            return form;
        });
}

function onRegistrationRedirect(ev, form) {
    GLOBALS.LOCKS.submissionInProgress = true;
    var redirect = "/site-register?redirectTo=" + encodeURIComponent(window.location.pathname + window.location.search + window.location.hash);
    if (form.nextPage && !form.isLastPage()) {
        // when they come in url hash, pages they look like this: page1, page2...
        var hash = encodeURIComponent("#page" + (form.page + 2));
        // %23 - uri encoded #
        if (redirect.match(/%23.+$/)) {
            redirect = redirect.replace(/%23.+$/, hash);
        } else {
            redirect += hash;
        }
    }
    var components = [];
    Object.keys(SERVICE_FORMS).forEach(function (formKey) {
        if (!SERVICE_FORMS[formKey] || !SERVICE_FORMS[formKey][0]) return;
        components = components.concat(utils.getPrefillTaggedFormioComponents(SERVICE_FORMS[formKey][0]));
    });
    if (components && components.length) {
        var params = {};
        components.forEach(function (component) {
            component.tags.forEach(function (tag) {
                if (!tag.startsWith("prefill_register_")) return;
                if (!form.data[component.key]) return;
                var paramName = tag.slice("prefill_register_".length);
                if (!paramName) return;
                if (!form.data[component.key]) return;
                params["prefill_register_" + paramName] = form.data[component.key].trim();
            });
        });
        const parsedParams = new URLSearchParams();
        Object.keys(params).forEach(key => {
            parsedParams.append(key, encodeURIComponent(params[key]));
        });
        if (parsedParams.toString()) {
            redirect += '&' + parsedParams.toString();
        }
    }
    GLOBALS.LOCKS.submissionInProgress = false;
    var promises = [
        form.executeSubmit(form.options),
        backend.saveFile(options.file_id, form.submission.data, true)
    ];

    Promise.all(promises).then(function (res) {
        GLOBALS.LOCKS.submissionInProgress = false;
        window.location.href = redirect;
    }).catch(function (err) {
        GLOBALS.LOCKS.submissionInProgress = false;
    });
}